// ChatWindow.js
import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const ChatWindow = () => (
  
  <div className="chat-window">
  
    <title>Assistente de Vendas Amazon BR - Celulares</title>
    <meta name="description" content="Descubra os celulares mais populares na Amazon Brasil com nosso assistente de vendas inteligente. Acompanhe avaliações, comparações e as melhores ofertas!" />

            <div className="header">
            <img src="/images/logoassistenteamazon.png" className="header-logo" />
            <div className="header-nav">
            <Link to="/xiaomiredmi12c">Xiaomi Redmi 12C</Link>
            <Link to="/maxtitanium">Creatina em pó - Max Titanium</Link>
            <Link to="/raqueteeletrica">Raquete Elétrica Mata Insetos Recarregável USB Multilaser</Link>
            <Link to="/xiaomi12">Xiaomi Redmi Note 12</Link>
            <Link to="/balancadigital">Balança Digital de Cozinha SF-400</Link>
            <Link to="/echodot">Echo Dot 5ª Geração da Amazon</Link>
            <Link to="/fitaduplaface">Fita Dupla Face Scotch</Link>
            <Link to="/sanduicheira">Sanduicheira Minigrill Gourmet Multilaser</Link>
            <Link to="/headset">Headset Gamer HyperX Cloud Stinger Core</Link>
            <Link to="/liquidificador">Liquidificador Turbo Power da Mondial</Link>
            <Link to="/xiaomi12s">Xiaomi Redmi Note 12S</Link>
            <Link to="/ventilador">Ventilador de Coluna Mallory</Link>
            <Link to="/motorolamotoe13">Motorola Moto E13</Link>
            <Link to="/livrotaleb">Livro 'Arriscando a Própria Pele'</Link>
            <Link to="/firestick">Fire TV Stick Amazon</Link>
            <Link to="/motorolamotoe22">Motorola Moto E22</Link>
          
            <Link to="/motog53">Motorola Moto G53</Link>
            <Link to="/cuecas">Cueca Boxer</Link>
            <Link to="/samsunggalaxym14">Samsung Galaxy M14</Link>
            <Link to="/samsunggalaxya04">Samsung Galaxy A04e</Link>
            <Link to="/kindle">Kindle 11ª Geração</Link>
            <Link to="/roku">Roku Express 4K</Link>
            <Link to="/samsunggalaxya14">Samsung Galaxy A14</Link>
            <Link to="/echostudio">Echo Studio Amazon</Link>
            <Link to="/amazfit">Amazfit GTS 2 Mini</Link>
            <Link to="/bandaelastica">Kit 5 Faixas Elásticas Mini Band da AB MÍDIA</Link>
            <Link to="/iphone7">iPhone 7</Link>
            <Link to="/iphone11">iPhone 11</Link>
            <Link to="/iphone12">iPhone 12</Link>
            <Link to="/iphone13">iPhone 13</Link>
            <Link to="/iphone14">iPhone 14</Link>
            <Link to="/iphone14pro">iPhone 14 Pro</Link>
            <Link to="/iphone14promax">iPhone 14 Pro Max</Link>
           
      
            </div>
            </div>
    <iframe
      src="https://www.chatbase.co/chatbot-iframe/5scgfTx2gHMSAJQw7DRgo"
      width="100%"
      style={{ height: '100%', minHeight: '700px' }}
      frameborder="0"
    ></iframe>
  </div>
);

export default ChatWindow;
