// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import ChatWindow from './ChatWindow';
import FAQ from './FAQ';
import Contact from './Contact';
import AvaliacaoCelular from './AvaliacaoCelular';
import './App.css';


const App = () => (
  <Router>

    <div className="main-content">
      <Routes>
      <Route path="/" element={<ChatWindow />} />
      <Route path="/:modeloCelular" element={<AvaliacaoCelular />} />
      </Routes>
    </div>
  </Router>
);

export default App;
